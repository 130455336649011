<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-0 pl-5 pr-4>
            <v-layout row>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-flex xs12 lg5 md6 class="align-center px-1 pb-0 pt-3">
                        <v-overlay
                            :value="loading.settings"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    ></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-data-table
                            :footer-props="{
                                itemsPerPageOptions: [20,50,-1],
                                showCurrentPage: true,
                                showFirstLastPage: true
                            }"
                            :headers="headers"
                            :items="allAppicSettings"
                            :items-per-page="-1"
                            :search="searchTerm"
                            :sort-by="['AppicSetting.title']"
                            :options.sync="tableOptions"
                            class="mt-0 appic-table-light specification-table"
                            dense
                            fixed-header
                            id="appicSettingsTable"
                            item-key="AppicSetting.id"
                        >
                            <template v-slot:item.AppicSetting.title="{ item }">
                                <span class="font-weight-bold">{{ item.AppicSetting.title }}</span>
                            </template>
                            <template v-slot:item.AppicSetting.value="{ item }">
                                <p class="indigo--text darken-4" style="overflow-wrap: normal">{{ item.AppicSetting.value }}</p>
                            </template>
                            <template v-slot:item.AppicSetting.id="{ item }">
                                <div class="text-center">
                                    <v-menu>
                                        <template v-slot:activator="{ on: menu }">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on: tooltip }">
                                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                                        <v-icon>more_vert</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('message.moreActions') }}</span>
                                            </v-tooltip>
                                        </template>
                                        <v-list dense>
                                            <v-list-item class="font-sm" @click="openEditSettingDialog(item.AppicSetting.id, item.AppicSetting.title, item.AppicSetting.value)">
                                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateSetting') }}
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </template>
                        </v-data-table>
                    </v-flex>
                </app-card>
            </v-layout>
        </v-container>
        <v-dialog
            persistent
            v-model="editDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="500px"
            min-width="500px"
            max-width="600px"
        >
            <v-card>
                <v-card-title class="success white--text">
                    <span>{{ $t('message.updateAppicSetting') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                        lazy-validation
                        ref="appicSettingForm"
                        v-model="validForm"
                    >
                        <v-layout row pt-3>
                            <v-flex xs6 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.title') }}</v-flex>
                            <v-flex xs6 lg9 md7 py-1>{{ selectedAppicSettingTitle }}</v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs6 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.value') }}</v-flex>
                            <v-flex xs6 lg9 md7 py-1>
                                <v-text-field
                                    :value="selectedAppicSettingValue"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="newAppicSettingValue = $event"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn color="default" small @click="closeEditSettingDialog()" >{{$t('message.dismiss')}}</v-btn>
                    <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveAppicSetting()"
                    >{{ $t('message.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {api} from "Api";

export default {
    name: "AppicSettings",
    data() {
        return {
            allAppicSettings: [],
            editDialog: false,
            loader: false,
            loading: {
                save: false,
                settings: false
            },
            newAppicSettingValue: null,
            selectedAppicSettingId: null,
            selectedAppicSettingTitle: null,
            selectedAppicSettingValue: null,
            searchTerm: null,
            tableOptions: {
                page: -1
            },
            validForm: true
        }
    },
    computed: {
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'AppicSetting.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.setting'),
                    value: 'AppicSetting.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.value'),
                    value: 'AppicSetting.value',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
            ]
        }
    },
    methods: {
        closeEditSettingDialog() {
            this.selectedAppicSettingId = null
            this.selectedAppicSettingTitle = null
            this.selectedAppicSettingValue = null
            this.editDialog = false
        },
        getAllAppicSettings() {
            this.loading.settings = true
            api
                .get('/admin/appic-settings')
                .then((response) => {
                    this.allAppicSettings = response.data.data
                    this.loading.settings = false
                })
                .catch(() => {
                    this.loading.settings = false
                })
        },
        openEditSettingDialog(selectedId, selectedTitle, selectedValue) {
            this.selectedAppicSettingId = selectedId
            this.selectedAppicSettingTitle = selectedTitle
            this.selectedAppicSettingValue = selectedValue
            this.editDialog = true
        },
        saveAppicSetting() {
            this.loading.save = true
            api
                .put("/admin/appic-settings/" + this.selectedAppicSettingId,{
                    setting: {
                        value: this.newAppicSettingValue
                    }
                })
                .then(response => {
                    if(response.data.status == 'success'){
                        this.$toast.success(this.$t('message.successes.settingUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.loading.save = false
                        this.editDialog = false
                        this.getAllAppicSettings()
                    } else {
                        this.$toast.error(this.$t('message.errors.settingNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    }
                })
                .catch(error => {
                    this.$toast.error(this.$t('message.errors.settingNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.save = false
                })
        }
    },
    created() {
        if(this.allAppicSettings.length == 0) this.getAllAppicSettings()
    }
}
</script>

<style>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table-header th {
    white-space: nowrap;
}
</style>